import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage} from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import {LOCALES} from "../core/i18n/locales"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// const items: MenuProps['items'] = [
//   getItem('Navigation Two', 'sub2', null, [
//     getItem('Option 5', '5'),
//     getItem('Option 6', '6'),
//   ]),

//   getItem('Navigation Three', 'sub4', null, [
//     getItem('Option 9', '9'),
//     getItem('Option 10', '10'),
//     getItem('Option 11', '11'),
//     getItem('Option 12', '12'),
//   ])
// ];

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store, adminStore } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()

  const [headerToggled, setHeaderToggled] = useState(true)
  const [headerTgl, setHeaderTgl] = useState(false)

  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const [items, setItems] = useState([] as MenuProps['items'])

  const onMouseUp = (e: any) => {
    var container = document.getElementById("ddp_menu")

    console.log("PRE-FIRREEEE", e)
    if (container != null && !container.contains(e.target)) {
      //container.style.display = 'none'
      console.log("FIRREEEE!!!", e)
      closeMenu()
    }
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "hidden"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.addEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true)
      setTimeout(() => {
        setIsMenuOpen(prev => prev = true)
      }, 300)
    }, 100)
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false)
    setIsMenuOpen(prev => prev = false)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "scroll"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.removeEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
    }, 300);
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      window.scroll(0, 0)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  const isHeaderBg = () => {
    return location.pathname == "/contacts" || location.pathname == "/about"
  }

  useEffect(() => {
    let scrFix = 0;

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      
      if (width <= 768 && location.pathname == "/") {
        return
      }

      if (scrollTop >= 60) {
        store.setIsHeaderLocked(true)
      } else {
        store.setIsHeaderLocked(false)
      }

      console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = false)
      }

      scrFix = parseInt(`${scrollTop}`);
      
      if (location.pathname.includes("/projects") && store.selectedProject != null) {
        if (scrollTop > window.innerHeight - 50) {
          setHeaderTgl(prev => prev = true)
        } else {
          setHeaderTgl(prev => prev = false)
        }
      } else {
        setHeaderTgl(prev => prev = false)
      }
    }
    
    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      {/* <div className={styles.mob_menu + (!headerToggled ? " " + styles.fixedd : "")} style={{
        display: isMenuShown ? "block" : "none",
        opacity: isMenuVisible ? "1" : "0",
        top: isMenuVisible ? "0px" : "-294px"
      }}>
        <div className={styles.mm_wrap}>
          <div className={styles.mm_w_logo}><img src="/assets/images/logo.svg" /></div>
          <div className={styles.mm_w_items}>
            <a href="/projects" className={(location.pathname == "/projects" ? " " + styles.active : "")}>
              <FormattedMessage id="menu_projects" />
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
            </a>
            {adminStore.isAboutActive ? <a href="/about" className={(location.pathname == "/about" ? " " + styles.active : "")}>
              <FormattedMessage id="menu_about" />
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
            </a> : <></>}
            <a href="/services" className={(location.pathname == "/services" ? " " + styles.active : "")}>
              <FormattedMessage id="menu_services" />
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
            </a>
            <a href="/contacts" className={(location.pathname == "/contacts" ? " " + styles.active : "")}>
              <FormattedMessage id="menu_contacts" />
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
            </a>
          </div>
          <div className={styles.mm_w_langs}>
            <div>RU</div>
            <div>ENG</div>
          </div>
        </div>
      </div> */}

      {/* <div className={styles.menu_open + (isMenuOpen ? " " + styles.openned : "") + (!headerToggled && !isMenuShown ? " " + styles.tgl : "")} onClick={() => isMenuShown ? closeMenu() : openMenu()}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="6" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "black" : "black"}/>
          <rect y="13" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "black" : "black"}/>
        </svg>
      </div> */}

      <header className={styles.header + (store.isHeaderLocked ? "" : " " + styles.noblur) + (!headerToggled && !isMenuShown ? " " + styles.fixed : "") + (headerTgl ? " " + styles.tgl : "") + (store.isHeaderLocked ? " " + styles.white : "") + (location.pathname.includes("/projects") && !headerTgl && store.selectedProject != null ? " " : "")} style={{
        backgroundColor: isHeaderBg() ? "#fff" : "#fff"
      }}>
        <div className="container">
          <div className="row">
            <div className={(width > 768 ? "col-6 col-md-6 " : "col-6 ")}>
              <div className={styles.logo}><a href="/"><img src={!headerTgl && location.pathname.includes("/projects") && store.selectedProject != null ? `/assets/images/logo.svg` : `/assets/images/logo.svg`} /></a></div>
            </div>
            <div className={(width > 768 ? "col-6 col-md-6 " : "col-6 ") + styles.ending}>
              <div className={styles.mn_dp_open + (isMenuVisible ? " " + styles.openned : "")} onClick={() => isMenuShown ? closeMenu() : openMenu()}>
                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="6" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "black" : "black"}/>
                  <rect y="13" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "black" : "black"}/>
                </svg> */}
                {/* <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 1.5H0" stroke="black" stroke-width="1.5"/>
                  <path d="M20 6.5H0" stroke="black" stroke-width="1.5"/>
                </svg> */}

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 6.3501H0" stroke="black" stroke-width="1.5"/>
                  <path d="M20 12.6001H0" stroke="black" stroke-width="1.5"/>
                </svg>

              </div>
            </div>
            <div className="col-12">
              <div id="ddp_menu" className={styles.drop_menu + (isMenuOpen ? " " + styles.openned : "")} style={width > 768 ? {height: !isMenuVisible ? "0px" : "266px"} : {right: !isMenuVisible ? "-100%" : "0%"}}>
                <div className={styles.mask}></div>
                <div className={styles.dm_items + (!adminStore.transActive ? " " + styles.mp : "")}>
                  <a href="/projects" className={(location.pathname == "/projects" ? " " + styles.active1 : "")}>
                    <FormattedMessage id="menu_projects" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
                  </a>
                  {adminStore.isAboutActive ? <a href="/about" className={(location.pathname == "/about" ? " " + styles.active1 : "")}>
                    <FormattedMessage id="menu_about" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
                  </a> : <></>}
                  <a href="/services" className={(location.pathname == "/services" ? " " + styles.active1 : "")}>
                    <FormattedMessage id="menu_services" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
                  </a>
                  <a href="/contacts" className={(location.pathname == "/contacts" ? " " + styles.active1 : "")}>
                    <FormattedMessage id="menu_contacts" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none"><path d="M0 1L29 1" stroke="#EB6216"/></svg>
                  </a>
                </div>
                {adminStore.transActive ? <div className={styles.dm_langs}>
                  <div className={store.locale == LOCALES.ENGLISH ? "" : styles.active} onClick={() => store.setLocale(LOCALES.RUSSIAN)}>RU</div>
                  <div className={store.locale == LOCALES.ENGLISH ? styles.active : ""} onClick={() => store.setLocale(LOCALES.ENGLISH)}>ENG</div>
                </div> : <></>}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)