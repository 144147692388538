import { DeleteOutlined } from "@ant-design/icons"
import { message, Switch, Upload, UploadProps } from "antd"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import Modal from "../../../components/Modal"
import {ApiService} from "../../../core/api/api"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"

import styles from "../../../styles/AdminManager.module.scss"

const HomeManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()
  
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isDeleteImgVisible, setIsDeleteImgVisible] = useState(false)

  const [isEditVisible, setIsEditVisible] = useState(false)
  
  const [transName, setTransName] = useState("")
  const [transValue, setTransValue] = useState("")
  const [transValueEn, setTransValueEn] = useState("")
  const [selectedTrans, setSelectedTrans] = useState(null as any | null)

  const [ftAddress, setFtAddress] = useState("")
  const [ftAddressEn, setFtAddressEn] = useState("")
  const [ftCopys, setFtCopys] = useState("")
  const [ftCopysEn, setFtCopysEn] = useState("")
  const [ftReqs, setFtReqs] = useState("")
  const [ftReqsEn, setFtReqsEn] = useState("")
  const [ftAddressUrl, setFtAddressUrl] = useState("")
  const [ftAddressSoc1, setFtAddressSoc1] = useState("")
  const [ftAddressSoc1En, setFtAddressSoc1En] = useState("")
  const [ftAddressSoc1Url, setFtAddressSoc1Url] = useState("")
  const [ftAddressSoc2, setFtAddressSoc2] = useState("")
  const [ftAddressSoc2En, setFtAddressSoc2En] = useState("")
  const [ftAddressSoc2Url, setFtAddressSoc2Url] = useState("")
  const [ftAddressSoc3, setFtAddressSoc3] = useState("")
  const [ftAddressSoc3En, setFtAddressSoc3En] = useState("")
  const [ftAddressSoc3Url, setFtAddressSoc3Url] = useState("")

  const [hsSpeed, setHSSpeed] = useState("")
  const [images, setImages] = useState([] as string[])

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)

  const [selectedImg, setSelectedImg] = useState(0)

  const props: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onChange: async (info) => {
      setIsDataLoading(prev => prev = false)

      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setImages(prev => prev = [...prev, info.file.response.urls[0]])

        saveData([...images, info.file.response.urls[0]])
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const fetchData = () => {
    setImages(prev => prev = adminStore.homeImages)
    setHSSpeed(prev => prev = `${adminStore.homeSliderSpeed}`)

    setFtAddress(prev => prev = adminStore.footerData.address)
    setFtAddressEn(prev => prev = adminStore.footerData.address_en)
    setFtAddressUrl(prev => prev = adminStore.footerData.address_url)

    setFtCopys(prev => prev = adminStore.footerData.copys)
    setFtCopysEn(prev => prev = adminStore.footerData.copys_en)
    
    setFtReqs(prev => prev = adminStore.footerData.reqs)
    setFtReqsEn(prev => prev = adminStore.footerData.reqs_en)

    setFtAddressSoc1(prev => prev = adminStore.footerData.social1)
    setFtAddressSoc1En(prev => prev = adminStore.footerData.social1_en)
    setFtAddressSoc1Url(prev => prev = adminStore.footerData.social1_url)

    setFtAddressSoc2(prev => prev = adminStore.footerData.social2)
    setFtAddressSoc2En(prev => prev = adminStore.footerData.social2_en)
    setFtAddressSoc2Url(prev => prev = adminStore.footerData.social2_url)

    setFtAddressSoc3(prev => prev = adminStore.footerData.social3)
    setFtAddressSoc3En(prev => prev = adminStore.footerData.social3_en)
    setFtAddressSoc3Url(prev => prev = adminStore.footerData.social3_url)
  }

  const saveData = async (newArr: string[]) => {
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.core.updateSetting(2, {
      data: newArr
    })

    adminStore.setHomeImages(newArr)
    setImages(prev => prev = newArr)

    setIsDataLoading(prev => prev = false)
    message.success("Данные успешно обновлены!")
  }

  const deleteImgAction = async () => {
    setIsDeleteImgVisible(false)
    setIsDataLoading(true)

    let newArr: string[] = []
    images.forEach((image, ix) => {
      if (ix != selectedImg) {
        newArr.push(image)
      }
    })

    const res = await ApiService.core.updateSetting(2, {
      data: newArr
    })

    adminStore.setHomeImages(newArr)
    setImages(prev => prev = newArr)

    setIsDataLoading(false)
  }

  const saveSpeed = async () => {
    setIsDataLoading(true)

    const res = await ApiService.core.updateSetting(3, {
      data: {value: hsSpeed}
    })

    adminStore.setHomeSliderSpeed(parseInt(hsSpeed))
    setIsDataLoading(false)
    message.success("Данные успешно обновлены!")
  }

  const saveFooter = async () => {
    setIsDataLoading(true)

    const attrs: any = {
      copys: ftCopys,
      copys_en: ftCopysEn,
      social1: ftAddressSoc1,
      social1_en: ftAddressSoc1En,
      social1_url: ftAddressSoc1Url,
      social2: ftAddressSoc2,
      social2_en: ftAddressSoc2En,
      social2_url: ftAddressSoc2Url,
      social3: ftAddressSoc3,
      social3_en: ftAddressSoc3En,
      social3_url: ftAddressSoc3Url
    }

    const res = await ApiService.core.updateSetting(8, {
      data: attrs
    })

    adminStore.setFooterData(attrs)
    setIsDataLoading(false)
    message.success("Данные успешно обновлены!")
  }

  const handleOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = images.findIndex((item: any, ix) => ix == adminStore.dragId);
    const dropItem = images.findIndex(
      (item: any, ix) => ix == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveItem(dragItem! - 1, dropItem! - 1)

    console.log(arr)

    setImages(prev => prev = arr)

    saveData(arr)
  }

  const moveItem = (from: number, to: number) => {
    const f = images.findIndex((v: any, ix) => ix == (from + 1));
    const t = images.findIndex((v: any, ix) => ix == (to + 1));
    let slds: any[] = [];
    let sldsEx: any[] = [...images];

    //store.aSlides.splice(to, 0, f)
    images.forEach((itm: any, ix: number) => {
      if (ix == f) {
        slds.push(images[t])
      } else if (ix == t) {
        slds.push(images[f])
      } else {
        slds.push(itm)
      }
    })

    return slds
  }

  const toggleTrans = async () => {
    const newSt = !adminStore.transActive
    const res = await ApiService.core.updateSetting(7, {
      data: {value: newSt}
    })

    localStorage.setItem(StorageKeys.SETTINGS_TRANS_ST, `${newSt}`)
    adminStore.setTransActive(!adminStore.transActive)

    message.success("Функция перевода обновлена!")
  }

  const selectTrans = (item: any) => {
    setTransName(item.name)
    setTransValue(item.value)
    setTransValueEn(item.value_en)
    setSelectedTrans(item)
    setIsEditVisible(true)
  }

  const saveTrans = async () => {
    setIsEditVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newData: any[] = []
    adminStore.transes.forEach((val: any) => {
      if (val.name == transName) {
        newData.push({
          name: val.name,
          value: transValue,
          value_en: transValueEn
        })
      } else {
        newData.push(val)
      }
    })
    const res = await ApiService.core.updateSetting(5, {
      data: newData
    })

    adminStore.setTranses(newData)

    message.success("Перевод успешно обновлен!")

    setIsDataLoading(prev => prev = false)
  }

  const isSpeedChanged = () => {
    if (
      hsSpeed != `${adminStore.homeSliderSpeed}`
    ) {
      return true
    } else {
      return false
    }
  }

  const isFooterChanged = () => {
    if (
      ftCopys != adminStore.footerData.copys ||
      ftCopysEn != adminStore.footerData.copys_en ||
      ftAddressSoc1 != adminStore.footerData.social1 ||
      ftAddressSoc1En != adminStore.footerData.social1_en ||
      ftAddressSoc1Url != adminStore.footerData.social1_url ||
      ftAddressSoc2 != adminStore.footerData.social2 ||
      ftAddressSoc2En != adminStore.footerData.social2_en ||
      ftAddressSoc2Url != adminStore.footerData.social2_url ||
      ftAddressSoc3 != adminStore.footerData.social3 ||
      ftAddressSoc3En != adminStore.footerData.social3_en ||
      ftAddressSoc3Url != adminStore.footerData.social3_url
    ) {
      return true
    } else {
      return false
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Слайдер</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>SEO</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>Футер</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 4)}>Словарь</div>
              </div>
            </div>

            {activeTab == 2 ? <>
              <div className={"col-12 col-md-8 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Настройки SEO</h3>
                </div>
              </div>
            </> : <></>}

            {activeTab == 3 ? <>
              <div className={"col-12 col-md-8 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Настройки футера</h3>

                  <div className={styles.xform}>
                    
                    <div className={styles.flexed}>
                      <div className={styles.xform_input}>
                        <label htmlFor="ft_copys">Копирайт</label>
                        <input type="text" id="ft_copys" placeholder="Копирайт" value={ftCopys} onChange={(event) => setFtCopys(event.target.value)} />
                      </div>
                      {adminStore.transActive ? <>
                        <div className={styles.xform_input}>
                          <label htmlFor="ft_copys_en">Копирайт (англ.)</label>
                          <input type="text" id="ft_copys_en" placeholder="Копирайт (англ.)" value={ftCopysEn} onChange={(event) => setFtCopysEn(event.target.value)} />
                        </div>
                      </> : <></>}
                    </div>
                    
                  </div>

                  <button className={styles.xbtn} disabled={!isFooterChanged()} onClick={() => saveFooter()}>Сохранить</button>
                </div>
              </div>
            </> : <></>}

            {activeTab == 4 ? <>
              <div className={"col-12 col-md-8 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Перевод на языки</h3>
                  
                  <div className={styles.xforms}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={adminStore.transActive} id="trans_st" onChange={(event) => toggleTrans()} /> <span style={{marginLeft: "4px"}}>{adminStore.transActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                    <div className={styles.xtable}>
                      <div className={styles.xtable_head}>
                        <div>#</div>
                        <div>Наименование</div>
                        <div>Рус.</div>
                        <div>Англ.</div>
                        <div>Действие</div>
                      </div>
                      <div className={styles.xtable_body}>
                        {adminStore.transes.map((item: any, ix: number) => <div className={styles.xlist_item}>
                          <div className={styles.xlist_item__id}>{ix + 1}</div>
                          <div className={styles.xlist_item__name} onClick={() => {
                            selectTrans(item)
                          }}><span>{item.name}</span></div>
                          <div className={styles.xlist_item__pagename}>{item.value}</div>
                          <div className={styles.xlist_item__pagename}>{item.value_en}</div>
                          <div className={styles.xlist_item__actions}>
                            <button onClick={() => {
                              selectTrans(item)
                            }}><i className="far fa-pen-to-square"></i></button>
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div> 

                </div>
              </div>
            </> : <></>}

            {activeTab == 1 ? <>
              <div className={"col-12 col-md-4 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.setting_block}>
                  <h3>Скорость смены</h3>
                  
                  <div className={styles.xform}>
                    <div className={styles.xform_input}>
                      <label htmlFor="hs_speed">Скорость смены слайдов</label>
                      <input type="number" id="hs_speed" placeholder="Скорость смены слайдов" value={hsSpeed} onChange={(event) => setHSSpeed(event.target.value)} />
                    </div>
                  </div>

                  <button className={styles.xbtn} disabled={!isSpeedChanged()} onClick={() => saveSpeed()}>Сохранить</button>
                </div>
              </div>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative", borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={styles.setting_block} style={{position: "relative"}}>
                  <h3>Изображения</h3>

                  <div className={styles.xactions} style={{top: "-10px"}}>
                    <Upload {...props}><div className={styles.xactions_item + " " + styles.successs}>Добавить изображение</div></Upload>
                  </div>

                  <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.</p>
                      
                  <div className={styles.flexed_imgs}>
                    {images.map((image, ix) => <div 
                      draggable={true}
                      key={ix}
                      id={`${ix}`}
                      onDragOver={(e) => handleOver(e)}
                      onDragStart={(e) => handleDrag(e)}
                      onDrop={(e) => handleDrop(e)}
                      style={{backgroundImage: `url(${image})`}}>
                      <DeleteOutlined rev={null} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()

                        setSelectedImg(ix)
                        setIsDeleteImgVisible(true)
                      }} />
                    </div>)}
                  </div>
                </div>
              </div>
            </> : <></>}
          </div>
        </div>
      </div>

      <Modal
        visible={isEditVisible}
        onClose={() => setIsEditVisible(false)}
        title="Изменить перевод"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование</label>
                <input type="text" id="tr_name" disabled={true} placeholder="Наименование перевода" value={transName} onChange={(event) => setTransName(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Рус. перевод</label>
                <input type="text" id="tr_name" placeholder="Русский" value={transValue} onChange={(event) => setTransValue(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Англ. перевод</label>
                <input type="text" id="tr_name" placeholder="Английский" value={transValueEn} onChange={(event) => setTransValueEn(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => saveTrans()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteImgVisible}
        onClose={() => setIsDeleteImgVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteImgAction()}>Удалить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(HomeManager)