import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage, useIntl} from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";
import {LOCALES} from "../core/i18n/locales";
import {isElementInViewport, stripPhone} from "../core/utils/utils";

import styles from "../styles/Footer.module.scss"

const Footer: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const {formatMessage: f} = useIntl()
  
  const [isVisible, setIsVisible] = useState(false)
  const [footerVisible, setFooterVisible] = useState(false)
  const [isToTopVisible, setIsToTopVisible] = useState(false)
  
  const toTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "", nodeName = event.target.nodeName.toLowerCase()

    if (nodeName == "img" || nodeName == "span") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      if (scrollTop >= 80) {
        setIsToTopVisible(true);
      } else {
        setIsToTopVisible(false);
      }

      // if (width > 768) {
        const ftr = document.getElementById("ftr")
        if (ftr != null && isElementInViewport(ftr)) {
          setFooterVisible(prev => prev = true)
        }
      // }
    }

    // if (width > 768) {
      setTimeout(() => {
        setIsVisible(prev => prev = true)
        onScroll({target: {scrollTop: 0}})
      }, 400)
    // } else {
    //   setIsVisible(prev => prev = true)
    //   setFooterVisible(prev => prev = true)
    // }

    document.getElementById("root")!.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    adminStore.contacts != null ? <>
      {/*<div className={styles.to_top} onClick={() => toTop()} style={{opacity: isToTopVisible ? "1" : "0"}}><i className="far fa-chevron-up"></i></div>*/}
    
      <footer className={styles.footer} id="ftr" style={{
        opacity: footerVisible ? "1" : "0",
        bottom: footerVisible ? "0px" : "-15px"
      }}>
        <div className="container" style={{
          transition: "all 0.3s ease",
          opacity: isVisible ? "1" : "0"
        }}>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className={styles.footer_wrap}>
                <div className={styles.footer_copy}>{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.copys : adminStore.footerData.copys_en}</div>
                <div className={styles.footer_socials}>
                  {adminStore.contacts.socials.map(contact => <a href={contact.url} target="_blank">{width > 768 ? contact.name : contact.name!.slice(0, 2)}</a>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </> : <></>
  )
}

export default observer(Footer)
