import {DeleteOutlined, EditOutlined} from "@ant-design/icons"
import {Editor} from "@tinymce/tinymce-react"
import {message, Switch} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {ReactSortable} from "react-sortablejs"
import { Context } from "../../.."
import Modal from "../../../components/Modal"
import {ApiService} from "../../../core/api/api"
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"
import {IXServiceItemBlock, IXServicesConfig} from "../../../core/models/IXServices"

import styles from "../../../styles/AdminManager.module.scss"

const ServicesManager: FC = () => {

  const { adminStore, store } = useContext(Context)

  const { width } = useWindowDimensions()

  const location = useLocation()
  const navigate = useNavigate()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)
  
  const [activeTab, setActiveTab] = useState(1)
  const [dataList, setDataList] = useState([] as IXServiceItemBlock[])

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isAddBlockVisible, setIsAddBlockVisible] = useState(false)
  const [isEdtBlockVisible, setIsEdtBlockVisible] = useState(false)
  const [isDelBlockVisible, setIsDelBlockVisible] = useState(false)
  
  const [selectedBlock, setSelectedBlock] = useState(null as IXServiceItemBlock | null)
  const [blockToggled, setBlockToggled] = useState(false)
  const [blockTitle, setBlockTitle] = useState("")
  const [blockTitleEn, setBlockTitleEn] = useState("")
  const [blockText, setBlockText] = useState("")
  const [blockTextT, setBlockTextT] = useState("")
  const [blockTextEn, setBlockTextEn] = useState("")
  const [blockTextEnT, setBlockTextEnT] = useState("")
  
  const fetchData = async () => {

  }

  const getTabName = () => {
    if (activeTab == 1) {
      return "Проект"
    } else if (activeTab == 2) {
      return "Смета"
    } else if (activeTab == 3) {
      return "Комплектация"
    } else if (activeTab == 4) {
      return "Авторский надзор"
    }
  }

  const addBlockAction = async () => {
    setIsAddBlockVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXServicesConfig = {
      project: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.project))},
      estimate: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.estimate))},
      equipment: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.equipment))},
      supervision: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.supervision))}
    }

    let path: "project" | "estimate" | "equipment" | "supervision" = "project"
    if (activeTab == 2) {
      path = "estimate"
    } else if (activeTab == 3) {
      path = "equipment"
    } else if (activeTab == 4) {
      path = "supervision"
    }

    let bls = props[path].blocks.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))

    props[path].blocks.push({
      id: bls[bls.length - 1].id + 1,
      title: blockTitle,
      title_en: blockTitleEn,
      text: editorRef.current.getContent(),
      text_en: adminStore.transActive ? editorRef2.current.getContent() : ""
    })

    console.log("bls", props)

    const res = await ApiService.core.updateSetting(11, {
      data: props
    })

    adminStore.setServicesConfig(props)
    setupBlocks()

    setIsDataLoading(prev => prev = false)

    message.success("Блок успешно добавлен!")
  }

  const saveBlockAction = async () => {
    setIsEdtBlockVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXServicesConfig = {
      project: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.project))},
      estimate: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.estimate))},
      equipment: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.equipment))},
      supervision: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.supervision))}
    }

    let path: "project" | "estimate" | "equipment" | "supervision" = "project"
    if (activeTab == 2) {
      path = "estimate"
    } else if (activeTab == 3) {
      path = "equipment"
    } else if (activeTab == 4) {
      path = "supervision"
    }

    let bls = props[path].blocks.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))

    let blks: IXServiceItemBlock[] = []
    props[path].blocks.forEach((block) => {
      if (block.id == selectedBlock!.id) {
        blks.push({
          id: block.id,
          title: blockTitle,
          title_en: blockTitleEn,
          text: editorRef.current.getContent(),
          text_en: adminStore.transActive ? editorRef.current.getContent() : ""
        })
      } else {
        blks.push(block)
      }
    })

    props[path].blocks = blks

    console.log("bls", props)

    const res = await ApiService.core.updateSetting(11, {
      data: props
    })

    adminStore.setServicesConfig(props)
    setupBlocks()

    setIsDataLoading(prev => prev = false)

    message.success("Блок успешно сохранен!")
  }

  const delBlockAction = async () => {
    setIsDelBlockVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXServicesConfig = {
      project: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.project))},
      estimate: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.estimate))},
      equipment: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.equipment))},
      supervision: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.supervision))}
    }

    let path: "project" | "estimate" | "equipment" | "supervision" = "project"
    if (activeTab == 2) {
      path = "estimate"
    } else if (activeTab == 3) {
      path = "equipment"
    } else if (activeTab == 4) {
      path = "supervision"
    }

    let bls = props[path].blocks.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))

    props[path].blocks = props[path].blocks.filter(v => v.id != selectedBlock!.id)

    console.log("bls", props)

    const res = await ApiService.core.updateSetting(11, {
      data: props
    })

    adminStore.setServicesConfig(props)
    setupBlocks()

    setIsDataLoading(prev => prev = false)

    message.success("Блок успешно удален!")
  }

  const saveBlocksAction = async () => {
    setIsEdtBlockVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXServicesConfig = {
      project: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.project))},
      estimate: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.estimate))},
      equipment: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.equipment))},
      supervision: {...JSON.parse(JSON.stringify(adminStore.servicesConfig!.supervision))}
    }

    let path: "project" | "estimate" | "equipment" | "supervision" = "project"
    if (activeTab == 2) {
      path = "estimate"
    } else if (activeTab == 3) {
      path = "equipment"
    } else if (activeTab == 4) {
      path = "supervision"
    }

    props[path].blocks = [...dataList]

    console.log("bls", props)

    const res = await ApiService.core.updateSetting(11, {
      data: props
    })

    adminStore.setServicesConfig(props)
    setupBlocks()

    setIsDataLoading(prev => prev = false)

    message.success("Блок успешно сохранен!")
  }

  const setupBlocks = () => {
    if (adminStore.servicesConfig != null) {
      if (activeTab == 1) {
        setDataList(prev => prev = adminStore.servicesConfig!.project.blocks)
      } else if (activeTab == 2) {
        setDataList(prev => prev = adminStore.servicesConfig!.estimate.blocks)
      } else if (activeTab == 3) {
        setDataList(prev => prev = adminStore.servicesConfig!.equipment.blocks)
      } else if (activeTab == 4) {
        setDataList(prev => prev = adminStore.servicesConfig!.supervision.blocks)
      }
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    setupBlocks()
  }, [activeTab])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    adminStore.servicesConfig != null ? <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}><span>Проект</span></div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}><span>Смета</span></div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}><span>Комплектация</span></div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 4)}><span>Авторский на...</span></div>
              </div>
            </div>

              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.setting_block} style={{position: "relative"}}>
                  <h3>{getTabName()}</h3>

                  <div className={styles.xactions} style={{top: "-10px"}}>
                    <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                      saveBlocksAction()
                    }}>Сохранить</div>
                    <div className={styles.xactions_item + " " + styles.successs} onClick={() => {
                      setBlockToggled(false)
                      setBlockTitle("")
                      setBlockTitleEn("")
                      setBlockText("")
                      setBlockTextEn("")
                      setIsAddBlockVisible(true)
                    }}>Добавить блок</div>
                  </div>

                  <p className={styles.xdesc}>Вы можете менять расположение блоков.<br/>Для этого зажмите блок и перенесите на нужное место.</p>

                  <div className={styles.xblocklist1}>
                    <ReactSortable className={styles.xblocklist} list={dataList} setList={setDataList}>
                      {dataList.map((block, ix) => <div className={styles.xblocklist_item}>
                        <div className={styles.xblocklist_item__title}><span>#{ix + 1}</span> {block.title}</div>
                        <div className={styles.xblocklist_item__text} dangerouslySetInnerHTML={{__html: block.text}}></div>
                        <div className={styles.xblocklist_item__actions}>
                          <div onClick={() => {
                            setBlockToggled(prev => prev = block.toggler!)
                            setBlockTitle(prev => prev = block.title)
                            setBlockTitleEn(prev => prev = block.title_en)
                            setBlockText(prev => prev = block.text)
                            setBlockTextEn(prev => prev = block.text_en)
                            setSelectedBlock(prev => prev = block)
                            setIsEdtBlockVisible(prev => prev = true)
                          }}><EditOutlined rev={null} /></div>
                          <div onClick={() => {
                            setSelectedBlock(prev => prev = block)
                            setIsDelBlockVisible(prev => prev = true)
                          }}><DeleteOutlined rev={null} /></div>
                        </div>
                      </div>)}
                    </ReactSortable>
                  </div>


                </div>
              </div>

          </div>
        </div>
      </div>

      <Modal
        visible={isEdtBlockVisible}
        title="Изменить блок"
        maxWidth={width > 1200 ? (adminStore.transActive ? "1100px" : "750px") : "550px"}
        onClose={() => setIsEdtBlockVisible(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                <label htmlFor="">Изначально скрыт? (моб.)</label>
                <Switch defaultChecked={blockToggled} id="trans_st" onChange={(event) => setBlockToggled(event)} /> <span style={{marginLeft: "4px"}}>{blockToggled ? "Вкл" : "Выкл"}</span>
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                  <label htmlFor="">Заголовок (необязательно)</label>
                  <input type="text" placeholder="Заголовок" value={blockTitle} onChange={(event) => setBlockTitle(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Заголовок (англ.)</label>
                  <input type="text" placeholder="Заголовок (англ.)" value={blockTitleEn} onChange={(event) => setBlockTitleEn(event.target.value)} />
                </div> : <></>}
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                  <label htmlFor="">Текст</label>
                  <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={blockText}
                    onEditorChange={(content: string) => {
                      setBlockTextT(prev => prev = content)
                    }}
                    apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                    init={{
                      height: 300,
                      menubar: true,
                      language: "ru",
                      plugins: [
                        'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                        'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help | code',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                    }}
                  />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Текст (англ.)</label>
                  <Editor
                    onInit={(evt, editor) => editorRef2.current = editor}
                    initialValue={blockTextEn}
                    onEditorChange={(content: string) => {
                      setBlockTextEnT(prev => prev = content)
                    }}
                    apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                    init={{
                      height: 300,
                      menubar: true,
                      language: "ru",
                      plugins: [
                        'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                        'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help | code',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                    }}
                  />
                </div> : <></>}
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => saveBlockAction()}>Cохранить</button>
            </div>
          </div>
        </>} />

        <Modal
          visible={isAddBlockVisible}
          onClose={() => setIsAddBlockVisible(prev => prev = false)}
          maxWidth={width > 1200 ? (adminStore.transActive ? "1100px" : "750px") : "550px"}
          title="Добавить блок"
          content={<>
            <div className="modal_text">
              <div className={styles.xform}>
                <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                  <label htmlFor="">Изначально скрыт? (моб.)</label>
                  <Switch defaultChecked={blockToggled} id="trans_st" onChange={(event) => setBlockToggled(event)} /> <span style={{marginLeft: "4px"}}>{blockToggled ? "Вкл" : "Выкл"}</span>
                </div>
                
                <div className={styles.flexed}>
                  <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                    <label htmlFor="">Заголовок (необязательно)</label>
                    <input type="text" placeholder="Заголовок" value={blockTitle} onChange={(event) => setBlockTitle(event.target.value)} />
                  </div>
                  {adminStore.transActive ? <div className={styles.xform_input}>
                    <label htmlFor="">Заголовок (англ.)</label>
                    <input type="text" placeholder="Заголовок (англ.)" value={blockTitleEn} onChange={(event) => setBlockTitleEn(event.target.value)} />
                  </div> : <></>}
                </div>

                <div className={styles.flexed}>
                  <div className={styles.xform_input} style={{maxWidth: "100%"}}>
                    <label htmlFor="">Текст</label>
                    <Editor
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={blockText}
                      onEditorChange={(content: string) => {
                        setBlockTextT(prev => prev = content)
                      }}
                      apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                      init={{
                        height: 300,
                        menubar: true,
                        language: "ru",
                        plugins: [
                          'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help | code',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                      }}
                    />
                  </div>
                  {adminStore.transActive ? <div className={styles.xform_input}>
                    <label htmlFor="">Текст (англ.)</label>
                    <Editor
                      onInit={(evt, editor) => editorRef2.current = editor}
                      initialValue={blockTextEn}
                      onEditorChange={(content: string) => {
                        setBlockTextEnT(prev => prev = content)
                      }}
                      apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                      init={{
                        height: 300,
                        menubar: true,
                        language: "ru",
                        plugins: [
                          'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help | code',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                      }}
                    />
                  </div> : <></>}
                </div>
              </div>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={() => addBlockAction()}>Добавить</button>
              </div>
            </div>
          </>} />

      <Modal
        visible={isDelBlockVisible}
        onClose={() => setIsDelBlockVisible(prev => prev = false)}
        title="Удалить блок"
        content={selectedBlock != null ? <>
          <div className="modal_text">
            <p>Вы действительно хотите удалить блок №{selectedBlock.id}?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => delBlockAction()}>Удалить</button>
            </div>
          </div>
        </> : <></>} />
    </> : <></>
  )
}

export default observer(ServicesManager)