import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"

import styles from "../../../styles/AdminManager.module.scss"

const CoreManager: FC = () => {

  const { adminStore, store } = useContext(Context)
  const location = useLocation()

  const [oldPwd, setOldPwd] = useState("")
  const [newPwd, setNewPwd] = useState("")
  const [newPwdRpt, setNewPwdRpt] = useState("")

  const savePwd = async () => {
    const res = await ApiService.users.updateAccount({
      password: newPwd
    })

    setNewPwd(prev => prev = "")
    setNewPwdRpt(prev => prev = "")

    message.success("Пароль успешно изменен!")
  }

  const isPwdFilled = () => {
    if (
      newPwd.length < 3 || newPwdRpt.length < 3
    ) {
      return false
    } else {
      if (newPwd == newPwdRpt) {
        return true
      } else {
        return false
      }
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className={styles.setting_block}>
                <h3>Сменить пароль</h3>
                  
                <div className={styles.xform}>
                  <div className={styles.xform_input}>
                    <label htmlFor="new_pwd">Новый пароль</label>
                    <input type="text" id="new_pwd" placeholder="Введите новый пароль" value={newPwd} onChange={(event) => setNewPwd(event.target.value)} />
                  </div>
                  <div className={styles.xform_input}>
                    <label htmlFor="new_pwd">Повторите новый пароль</label>
                    <input type="text" id="new_pwd" placeholder="Повторите новый пароль" value={newPwdRpt} onChange={(event) => setNewPwdRpt(event.target.value)} />
                  </div>
                </div>

                <button className={styles.xbtn} disabled={!isPwdFilled()} onClick={() => savePwd()}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(CoreManager)