import React from "react";

const Home = React.lazy(() => import("../../pages/Home"))
const About = React.lazy(() => import("../../pages/About"))
const Projects = React.lazy(() => import("../../pages/Projects"))
const ProjectSingle = React.lazy(() => import("../../pages/ProjectSingle"))
const Contacts = React.lazy(() => import("../../pages/Contacts"))
const Services = React.lazy(() => import("../../pages/Services"))

const Policy = React.lazy(() => import("../../pages/Policy"))

const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  PROJECTS = '/projects',
  PROJECT_SINGLE = '/projects/:id',
  ABOUT = '/about',
  CONTACTS = '/contacts',
  SERVICES = '/services',

  POLICY = '/policy',

  ADMIN_AUTH = "/admin/auth"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.PROJECTS, exact: true, component: <Projects /> },
  { path: RouteNames.PROJECT_SINGLE, exact: true, component: <ProjectSingle /> },
  { path: RouteNames.ABOUT, exact: true, component: <About /> },
  { path: RouteNames.CONTACTS, exact: true, component: <Contacts /> },
  { path: RouteNames.SERVICES, exact: true, component: <Services /> },
  
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },

  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]
