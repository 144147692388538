import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { message, Switch, UploadProps } from "antd"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { ReactSortable } from "react-sortablejs"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { IXTeamItem, IXPartner, IXAboutSettings } from "../../../core/models/XAbout"
import { API_URL, StorageKeys } from "../../../core/utils/defaults"

import { Editor } from "@tinymce/tinymce-react"

import Dragger from "antd/es/upload/Dragger"
import imageCompression from "browser-image-compression"
import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"

const AboutManager: FC = () => {

  const { adminStore, store } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)
  
  const draggerProps: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const [activeTab, setActiveTab] = useState(1)

  const [aboutActive, setAboutActive] = useState(false)

  const [aboutText, setAboutText] = useState("")
  const [aboutTextT, setAboutTextT] = useState("")
  const [aboutTextEn, setAboutTextEn] = useState("")
  const [aboutTextEnT, setAboutTextEnT] = useState("")
  
  const [isDataLoading, setIsDataLoading] = useState(false)
  
  const fetchAboutData = async () => {
    if (adminStore.aboutSettings != null) {
      setAboutActive(adminStore.aboutSettings.status)

      
      setAboutText(adminStore.aboutSettings.about.text.ru)
      setAboutTextEn(adminStore.aboutSettings.about.text.en)
      
    } else {
      if (adminStore.aboutLoad < 3) {
        adminStore.setAboutLoad(adminStore.aboutLoad + 1)
        setTimeout(() => {
          fetchAboutData()
        }, 2000)
      } else {
        navigate("/")
      }
    }
  }

  const toggleState = async () => {

  }

  const buildFinalData = (): IXAboutSettings => {
    let newData: IXAboutSettings = {
      status: aboutActive,
      about: {
        text: {
          ru: editorRef.current.getContent(), 
          en: editorRef2.current.getContent()
        }
      }
    }

    return newData
  }

  const saveAboutData = async () => {
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()
    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    adminStore.setIsAboutActive(props.status)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")

    setIsDataLoading(prev => prev = false)
  }

  const isAboutDataChanged = () => {
    if (
      adminStore.aboutSettings != null &&
      (
        aboutActive != adminStore.aboutSettings.status ||
        aboutText != adminStore.aboutSettings.about.text.ru ||
        aboutTextEn != adminStore.aboutSettings.about.text.en
      )
    ) {
      return true
    } else {
      return false
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    adminStore.setAboutLoad(0)
    fetchAboutData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">

            {activeTab == 1 ? <>
              <div className={"col-12 col-md-9 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Страница "О нас"</h3>
                  
                  <div className={styles.xforms}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={aboutActive} checked={aboutActive} id="trans_st" onChange={(event) => setAboutActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включена" : "Выключена"}</span>
                    </div>
                  </div>

                  <div className={styles.xform} style={{marginTop: "20px"}}>
                    
                    <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                      <label htmlFor="about_text">Описание</label>
                      <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={aboutText}
                        onEditorChange={(content: string) => {
                          setAboutTextT(prev => prev = content)
                        }}
                        apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                        init={{
                          height: 300,
                          menubar: true,
                          language: "ru",
                          plugins: [
                            'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                            'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help | code',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                        }}
                      />
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                      <label htmlFor="about_text_en">Описание (англ.)</label>
                      <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={aboutText}
                        onEditorChange={(content: string) => {
                          setAboutTextT(prev => prev = content)
                        }}
                        apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                        init={{
                          height: 300,
                          menubar: true,
                          language: "ru",
                          plugins: [
                            'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                            'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help | code',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                        }}
                      />
                    </div> : <></>}

                  </div>

                  <button className={styles.xbtn} disabled={!isAboutDataChanged()} onClick={() => saveAboutData()}>Сохранить</button>
                </div>
              </div>
            </> : <></>}

          </div>
        </div>
      </div>

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(AboutManager)