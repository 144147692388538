import React, { FC, ReactElement, useEffect, useState } from "react";

import styles from "../styles/ManagerModal.module.scss";

interface ModalProps {
  visible: boolean
  title: string
  content: ReactElement | string
  footer?: ReactElement | string
  maxWidth?: string
  onClose: () => void
}

const ManagerModal: FC<ModalProps> = ({
  visible, title, content, footer, maxWidth = "550px", onClose
}) => {

  const [ hidingProceed, setHidingProceed ] = useState(false)
  const [ isVis, setIsVis ] = useState(false)

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        setHidingProceed(true)
        setTimeout(() => {
          onClose()
          setHidingProceed(false)
        }, 300)
        break
    }
  }

  const onCloseRef = () => {
    setIsVis(prev => prev = false)
    setTimeout(() => {
      setHidingProceed(true)
      setTimeout(() => {
        onClose()
        setHidingProceed(false)
      }, 300)
    }, 300)
  }

  useEffect(() => {
    setTimeout(() => {
      if (visible) {
        setIsVis(prev => prev = true)
      } else {
        setIsVis(prev => prev = false)
      }
    }, 100)
  }, [visible])

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  if (!visible) return null
  
  return (
    <div className={styles.modal} style={{opacity: hidingProceed ? "0" : "1"}}>
      <div className={styles.modal_dialog} style={{right: !isVis ? "-100%" : "0%"}} onClick={e => e.stopPropagation()}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_header__title}
            style={{
              textAlign: parseInt(maxWidth) > 420 ? "center" : "center"
            }}>{title}</h3>
          <span className={styles.modal_header__close} onClick={onCloseRef}>
            <i className="fal fa-times"></i>
          </span>
        </div>
        <div className={styles.modal_body}
          style={{
            padding: parseInt(maxWidth) > 420 ? "0px 0px" : "0px"
          }}>
          <div className={styles.modal_body__content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  )
}

export default ManagerModal