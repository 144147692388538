import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../.."
import Modal from "../../components/Modal"

import styles from "../../styles/AdminManager.module.scss"

import HomeManager from "./manager/HomeManager"
import AboutManager from "./manager/AboutManager"
import ContactsManager from "./manager/ContactsManager"
import ProjectsManager from "./manager/ProjectsManager"
import CoreManager from "./manager/CoreManager"
import useWindowDimensions from "../../core/hooks/useWindowDimensions"
import ServicesManager from "./manager/ServicesManager"

enum ManagerPage {
  HOME = "HOME",
  PROJECTS = "PROJECTS",
  ABOUT = "ABOUT",
  CONTACTS = "CONTACTS",
  SETTINGS = "SETTINGS",
  SERVICES = "SERVICES"
}

const ManagerPageTitle = {
  HOME: "Главная", PROJECTS: "Проекты", ABOUT: "О нас", SERVICES: "Услуги", CONTACTS: "Контакты", SETTINGS: "Настройки"
}

const AdminManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const { width } = useWindowDimensions()

  const [page, setPage] = useState(ManagerPage.HOME as ManagerPage)
  const [pageTitle, setPageTitle] = useState(ManagerPageTitle.HOME as string)
  
  const [isLogoutVisible, setIsLogoutVisible] = useState(false)

  const [menuOpenned, setMenuOpenned] = useState(false)

  const openMenu = () => {
    setMenuOpenned(prev => prev = !prev)
  }

  const openManager = () => {
    adminStore.setIsManagerVisible(true)
    if (location.pathname == "/about") {
      setPage(ManagerPage.ABOUT)
    } else if (location.pathname.includes("/projects")) {
      setPage(ManagerPage.PROJECTS)
    } else if (location.pathname.includes("/services")) {
      setPage(ManagerPage.SERVICES)
    } else if (location.pathname == "/contacts") {
      setPage(ManagerPage.CONTACTS)
    } else {
      setPage(ManagerPage.HOME)
    }

    document.body.style.overflowY = 'hidden'
    document.getElementsByTagName("html")[0].style.overflowY = 'hidden'

    setTimeout(() => {
      adminStore.setIsManagerVisibleOp(true)
    }, 100)
  }

  const logout = () => {
    store.logout()
    window.location.reload()
  }

  useEffect(() => {
    setPageTitle(prev => prev = ManagerPageTitle[page])
    setMenuOpenned(prev => prev = false)
  }, [page])

  useEffect(() => {
    
  }, [])

  return (
    <>
      <div 
        className={styles.manager_btn + (store.theme == "winter" && !location.pathname.includes("/summer") ? " " + styles.winter : "")}
        onClick={() => openManager()}><div><img src="/assets/images/warcraft.svg" /></div></div>
    
      <div className={styles.manager} style={{opacity: adminStore.isManagerVisibleOp ? "1" : "0", display: adminStore.isManagerVisible ? "flex" : "none"}}>
        <div className={styles.manager_wrap}>
          <div className={styles.manager_sidebar}>
            <div className={styles.manager_sidebar__logo}>MAGMA <b>CMS</b></div>
            <div className={styles.manager_sidebar__menu + (menuOpenned ? " " + styles.openned : "")}>
              <div className={page == ManagerPage.HOME ? styles.active : ""} onClick={() => setPage(ManagerPage.HOME)}>{ManagerPageTitle.HOME}</div>
              <div className={page == ManagerPage.PROJECTS ? styles.active : ""} onClick={() => setPage(ManagerPage.PROJECTS)}>{ManagerPageTitle.PROJECTS}</div>
              <div className={page == ManagerPage.ABOUT ? styles.active : ""} onClick={() => setPage(ManagerPage.ABOUT)}>{ManagerPageTitle.ABOUT}</div>
              <div className={page == ManagerPage.SERVICES ? styles.active : ""} onClick={() => setPage(ManagerPage.SERVICES)}>{ManagerPageTitle.SERVICES}</div>
              <div className={page == ManagerPage.CONTACTS ? styles.active : ""} onClick={() => setPage(ManagerPage.CONTACTS)}>{ManagerPageTitle.CONTACTS}</div>
              <div className={page == ManagerPage.SETTINGS ? styles.active : ""} onClick={() => setPage(ManagerPage.SETTINGS)}>{ManagerPageTitle.SETTINGS}</div>
              
              {width < 768 ? <>
                <div className={page == ManagerPage.SETTINGS ? styles.active : ""} onClick={() => setPage(ManagerPage.SETTINGS)}>{ManagerPageTitle.SETTINGS}</div>
                <div className={""} onClick={() => setIsLogoutVisible(true)}>Выйти</div>
              </> : <></>}
            </div>
            {/* <div className={styles.ma}></div> */}
            <div className={styles.manager_sidebar__logout} onClick={() => setIsLogoutVisible(true)}><i className="far fa-right-from-bracket"></i> Выйти</div>
            <div className={styles.manager_sidebar__mn + (menuOpenned ? " " + styles.openned : "")} onClick={() => openMenu()}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="20" height="1" fill={"#fff"}/>
                <rect y="13" width="20" height="1" fill={"#fff"}/>
              </svg>
            </div>
          </div>
          <div className={styles.manager_body}>
              {page == ManagerPage.HOME ? <HomeManager /> : <></>}
              {page == ManagerPage.PROJECTS ? <ProjectsManager /> : <></>}
              {page == ManagerPage.ABOUT ? <AboutManager /> : <></>}
              {page == ManagerPage.SERVICES ? <ServicesManager /> : <></>}
              {page == ManagerPage.CONTACTS ? <ContactsManager /> : <></>}
              {page == ManagerPage.SETTINGS ? <CoreManager /> : <></>}
            
          </div>
        </div>
      </div>

      <Modal
        visible={isLogoutVisible}
        title="Выйти из ПУ"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите выйти из Панели Управления сайтом?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => logout()} style={{marginBottom: "0px"}}>Выйти</button>
            </div>
          </div>
        </>}
        onClose={() => setIsLogoutVisible(false)} />
    </>
  )
}

export default observer(AdminManager)